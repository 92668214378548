<template>
  <div :class="`${theme}-theme`">
    <router-view></router-view>
  </div>
</template>

<script>
import { globalData } from './store/store';
import layout from './view/layout/layout.vue'
import env from '@/utils/env.js';
import { mapState } from 'pinia';

export default {
  name: 'App',
  components: {
    layout,
  },
  computed: {
    ...mapState(globalData, ['theme'])
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
    functiongetQueryString(name) {
      let geturl = window.location.href
      let getqyinfo = geturl.split('?')[1]   //qycode=1001&qyname=%E4%BC%81%E4%B8%9A%E5%BF%99   截取到参数部分
      let getqys = new URLSearchParams('?' + getqyinfo)  //将参数放在URLSearchParams函数中
      return getqys.get(name);
    }
  },
  mounted() {
    let pid = this.functiongetQueryString("pid")
    if (pid) {
      if (pid != 'undefined') {
        localStorage.pid = pid
      }

    }
    let token = this.functiongetQueryString("token")
    if (token) {//手机端跳过来会带上token 如果带来了 就存进去 这样就登录了
      if (token != 'undefined') {
        localStorage.token = token
      }

    }
    if (this._isMobile()) {
      // alert(1)
      //手机端访问 就跳转手机端去
      // alert(env.h5Url)
      let url = env.h5Url + "/#/pages/index/index?";
      if (localStorage.pid) {
        url = url + "&pid=" + localStorage.pid;
      }
      if (localStorage.token) {
        url = url + "&token=" + localStorage.token;
      }
      window.open(url, "_self");
    }
  }

}
</script>
