<template>
  <el-container>
    <el-aside :width="isCollapse ? '50px' : '140px'" class="menu-box">
      <div class="menu-title flex-row flex-center">
        <img src="https://wailikeji.oss-cn-beijing.aliyuncs.com/chatgpt/sys/chatgpt/11331692087338_.pic_hd.jpg"
          class="logo-img" :class="{ 'collapsed': isCollapse }" alt="">
      </div>
      <el-scrollbar class="page-scroll">
        <el-menu :default-active="`${menuIndex}`" :collapse="isCollapse">
          <el-menu-item :title="item.name" @click="menuBtn(item, index)" v-for="(item, index) in menuList" :key="index"
            :index="index.toString()">
            <component class="icon-tabbar" v-if="item.icon" :is="item.icon" />
            <img v-else :src="getImageUrl(item.img)" class="icon-tabbar" :class="{ 'selected': index === menuIndex }"
              alt="" />
            <template #title>{{ item.name }}</template>
          </el-menu-item>
        </el-menu>
      </el-scrollbar>
      <div @click="changeMenuCollapse" class="collapse pointer" :style="{ left: isCollapse ? '50px' : '140px' }">
        <el-icon size="12" color="#fff">
          <i-ep-arrow-right v-if="isCollapse" />
          <i-ep-arrow-left v-else />
        </el-icon>
      </div>
    </el-aside>
    <el-main class="right-box pl-15 pr-10 py-8">
      <navTop></navTop>
      <div class="mt-12">
        <router-view></router-view>
      </div>
    </el-main>
  </el-container>
</template>

<script lang="ts">
import { getImageUrl } from '@/utils/utils.js'
import navTop from '@/components/navTop/navTop.vue'
import { globalData } from '@/store/store.js'
import { mapActions, mapState } from 'pinia';
import { HomeFilled, ChatDotRound, Message, PictureFilled, List,
   VideoCameraFilled, Connection, CreditCard } from '@element-plus/icons-vue';

export default {
  data() {
    return {
      menuList: [
        {
          name: '快速问答',
          url: '/home/home',
          icon: markRaw(HomeFilled),
        },
        {
          name: 'AI绘画',
          url: '/home/paint',
          icon: markRaw(PictureFilled)
        },
        // {
        //   name: '虚拟数字人',
        //   url: '/home/virtualRobot',
        //   img: 'face.svg'
        // },
        {
          name: '知识库',
          url: '/home/chatpdf',
          img: 'pdf.svg'
        },
        {
          name: '视频二创',
          url: '/home/shortVideo',
          icon: markRaw(VideoCameraFilled)
        },
        {
          name: '我的提问',
          url: '/home/issureLog',
          icon: markRaw(ChatDotRound),
        },
        {
          name: '金币明细',
          url: '/home/coinLog',
          icon: markRaw(List)
        },
        {
          name: '快速充值',
          url: '/home/recharge',
          icon: markRaw(CreditCard)
        },
        {
          name: '个人中心',
          url: '/home/user',
          icon: markRaw(Connection)
        },
        {
          name: '发送反馈',
          url: '/home/feedback',
          icon: markRaw(Message)
        },
      ],
      getImageUrl,
    }
  },
  watch: {
    '$route.path'(newValue, oldValue) {
      const curMenuIndex = this.menuList.findIndex(item => item.url === newValue)
      if (curMenuIndex !== -1) {
        this.setMenuIndex(curMenuIndex)
      }
    }
  },
  computed: {
    ...mapState(globalData, ['isCollapse', 'menuIndex'])
  },
  methods: {
    ...mapActions(globalData, ['changeMenuCollapse', 'setMenuIndex']),
    menuBtn(item, index) {
      this.setMenuIndex(index)
      if (item.url) {
        this.$router.push({ path: item.url })
      }
    }
  },
  mounted() {
    // 窗口大小变化自动收缩左侧菜单
    window.onresize = () => {
      if ((window.innerWidth <= 800 && !this.isCollapse) || (window.innerWidth > 800 && this.isCollapse)) {
        this.changeMenuCollapse()
      }
    }
  },
  created() {
    // 根据url设置初始选中菜单
    const index = this.menuList.findIndex(menu => window.location.pathname.includes(menu.url));
    if (index !== -1) {
      this.setMenuIndex(index);
    }
  }
}
</script>

<style scoped lang="scss">
.menu-box {
  padding-top: 20px;
  height: 100vh;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  /* 加过渡给侧边导航*/
  transition: width 0.25s;
  -webkit-transition: width 0.25s;
  -moz-transition: width 0.25s;
  -webkit-transition: width 0.25s;
  -o-transition: width 0.25s;

  &::-webkit-scrollbar {
    display: none;
  }

  .page-scroll {
    height: calc(100% - 60px);
  }

  .page-scroll .el-scrollbar__wrap {
    overflow-x: hidden;
  }

  .menu-title {
    color: $theme-color;
    margin-bottom: 20px;
  }

  .collapse {
    position: fixed;
    background-color: rgba(#606266, .2);
    height: 46px;
    width: 12px;
    line-height: 46px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    top: 50vh;

    &:hover {
      background-color: rgba(#606266, .3);
    }
  }

  .logo-img {
    width: 75px;

    &.collapsed {
      width: 48px;
    }
  }

  .el-menu {
    border-right: none !important;
    /*加快侧边栏文字消失的速度*/
    transition: all 10ms;
  }

  --el-menu-base-level-padding: 12px !important;

  .el-menu-item {

    .icon-tabbar {
      width: 22px;
      height: 22px;
      margin-right: 8px;

      &.selected {
        filter: brightness(0) saturate(100%) invert(58%) sepia(58%) saturate(3621%) hue-rotate(190deg) brightness(102%) contrast(102%);
      }
    }
  }

  .el-menu--collapse {
    .icon-tabbar {
      margin-right: 0;
    }
  }
}

.right-box {
  height: 100vh;
  background-color: #FAFAFA;
}
</style>